Drupal.cl = Drupal.cl || {};

var site = site || {};
site.util = site.util || {};

/**
 * Add support for older browsers that don't have console.log
 */
var console = console || {
  log: function() {
    return false;
  }
};

/**
 * Add support for older browsers that don't have Object.keys
 */
Object.keys = Object.keys || function(o) {
  var result = [];
  for (var name in o) {
    if (o.hasOwnProperty(name))
      result.push(name);
  }
  return result;
};

(function($) {
  Drupal.behaviors.clClientToggle = {
    attach: function(context, settings) {
    // wire device toggle links
      if (site.client && site.client.controls) {
        site.client.controls();
      }
    }
  };

  Drupal.behaviors.mpLanguageToggle = {
    attach: function(context, settings) {
      if (typeof mp_language_id !== 'undefined') {
        $('input[name="LANGUAGE_ID"]').val(mp_language_id);
      }
    }
  };

  // setup the "back to top" link that will scoll the
  // page back to the top of screen when clicked
  Drupal.behaviors.backToTopToggle = {
    attach: function(context, settings) {
      var offset = 220;
      var duration = 500;

      $(window).on('scroll', _.throttle(function() {
        if ($(this).scrollTop() > offset) {
          $('.back-to-top').fadeIn(duration);
        } else {
          $('.back-to-top').fadeOut(duration);
        }
      }, 100));

      $('.back-to-top').on('click', function(event) {
        event.preventDefault();
        $('html, body').animate({scrollTop: 0}, duration);
        return false;
      });

      // get all links and look for links with the name attribute
      // these are/can be used for named anchors
      var anchoredLinks = {};
      $('a').each(function() {
        var name = $(this).attr('name');
        if (name) {
          anchoredLinks[name] = 1;
        }
      });

      // get all links that starts with a '#' and see which ones also are a part of our named anchors object
      // the matches will get a class added to them that will set an offset so the content comes down under
      // the sticky nav
      $('a[href^="#"]').each(function(index) {
        var href = $(this).attr('href');
        href = href.substring(1);

        if (anchoredLinks[href]) {
        // add offset class
          $('a[name^="' + href + '"]').addClass('anchorOffset');
        }
      });
    }
  };

  /**
 * Clinique base object
 */
  $.extend(Drupal.cl, {
  /**
   * Helper function to return TRUE or FALSE based on whether the personalization engine is enabled
   */
    hasPersonalization: function() {
      var settings = Drupal || {};
      settings = settings.settings || {};
      settings = settings.cl || {};
      personalization = settings.personalization || 0;
      return personalization === 1;
    },

    /**
   * Equivalent to php's is_numeric function
   */
    isNumeric: function(mixed_var) {
      return (typeof mixed_var === 'number' || typeof mixed_var === 'string') && mixed_var !== '' && !isNaN(mixed_var);
    },

    /**
   * Equivalent to C#.Net Dictionary<TKey, TValue>.TryGetValue method, but this method returns an anonymous object
   */
    tryGetObject: function(obj, key, val) {
      var objects = [];
      for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
          objects = objects.concat(Drupal.cl.tryGetObject(obj[i], key, val));
        } else if (i == key && obj[key] == val) {
          objects.push(obj);
        }
      }
      return objects;
    },

    /**
   * Equivalent to php's implode function
   */
    implode: function(glue, pieces) {
      var i = '',
          retVal = '',
          tGlue = '';

      if (arguments.length === 1) {
        pieces = glue;
        glue = '';
      }
      if (typeof pieces === 'object') {
        if (Object.prototype.toString.call(pieces) === '[object Array]') {
          return pieces.join(glue);
        }
        for (i in pieces) {
          retVal += tGlue + pieces[i];
          tGlue = glue;
        }
        return retVal;
      }
      return pieces;
    },

    /**
   * Returns the height of the #top region
   */
    getTopHeight: function() {
      var e = this.getElements();
      var h = e.top.outerHeight(true);
      return this.isNumeric(h) ? h : 0;
    },

    /**
   * Returns the padding at the top of the body element
   */
    getToolbarHeight: function() {
      var e = this.getElements();
      var h = e.toolbar.outerHeight(true);
      return this.isNumeric(h) ? h : 0;
    },

    /**
   * Returns the common elements referenced by many other functions
   */
    getElements: function() {
    // poor man's static caching
      if (typeof this.getElements.elements == 'undefined') {
        this.getElements.elements = {
          body: $('body'),
          toolbar: $('#toolbar'),
          top: $('#top'),
          nav: $('#navigation'),
          main: $('#main'),
          footer: $('#footer')
        };
      }

      return this.getElements.elements;
    },

    /**
   * Sets the widths of columns to equal percentages
   * Sets the heights of columns to equal when resized
   */
    responsiveColumns: function(columns) {
      var columnCount = columns.length;

      if (columnCount > 0) {
      // equal widths depending on number of columns
        var columnWidth = Math.floor(100 / columnCount);
        columns.each(function() {
          $(this).css('width', columnWidth + '%');
        });

        // equal heights
        var columnHeight = 0;
        $(window).resize(function() {
          columns.each(function() {
            $(this).css('height', 'auto');
            var thisHeight = $(this).height();
            if (thisHeight !== columnHeight) {
              columnHeight = thisHeight;
            }
          }).css('height', columnHeight + 'px');
        }).resize();
      }
    },

    /*
   * Equivalent of generic.template.get()
   */
    template: {
      js: function(path) {
        path = typeof path !== 'undefined' ? path : 'foobar_template';
        return $("script.inline-template[path='" + path + "']").html();
      },

      get: function(args) {
        var self = this;
        var path = args.path;
        var object = args.object;
        var callback = args.callback;
        var partials = args.partials;

        if (typeof path !== 'string') {
          return;
        }

        if (path.length < 1) {
          return;
        }

        data = typeof object !== 'undefined' ? object : {};
        partials = typeof partials !== 'undefined' ? partials : {};
        for (key in partials) {
          if (partials.hasOwnProperty(key)) {
            partials[key] = self.js(partials[key]);
          }
        }

        var template = self.js(path),
            out = Mustache.render(template, data, partials);

        if (typeof callback === 'function') {
          var ret = callback(out, data);

          // If the callback returns something, this get() method should do the same
          if (typeof ret !== 'undefined') {
            return ret;
          }
        }
      }
    },

    getQSParams: function() {
      var vars = [], hash;
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
      return vars;
    },

    fixedEncodeURIComponent: function(str) {
      return str.replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, '-').replace(/^(-)+|(-)+$/g, '');
    },

    hasQueryString: function(url) {
      if (_.isEmpty(url.split('?')[1])) {
        return false;
      } else {
        return true;
      }
    }
  });

  /**
 * This behavoir adds functionality to initialize CTA buttons.
 * Note: a "cta" button is defined as a link with both "button" and "cta" classes.
 *
 * Author: Greg Amaroso
 */
  Drupal.behaviors.ctaButton = {
    cl: Drupal.cl,
    actionCbs: {},

    // Helper function, similar to PHP's empty() function
    // returns boolean
    isEmpty: function(str) {
      return !str || !/[^\s]+/.test(str);
    },

    // Helper function, similar to PHP's is_object() function
    // returns boolean
    isObject: function(mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }
      return mixed_var !== null && typeof mixed_var === 'object';
    },

    // IE-friendly way to determine if an object has properties
    // returns boolean true if any properties exist, false otherwise
    hasProps: function(obj) {
      if (this.isObject(obj) == false) {
        return false;
      }

      var length = 0;
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          length++;
        }
      }
      return length > 0;
    },

    getActionClass: function(classes) {
      var len = classes.length;
      var isa = this.isActionClass;
      for (var i = len - 1; i >= 0; i -= 1) {
        if (isa(classes[i])) {
          return classes[i];
        }
      }

      return false;
    },

    isActionClass: function(a) {
      return a.substring(0, 7) == 'action-';
    },

    /**
   * Options: quickview, spp, add_to_bag, add_to_favorites, other, none
   */
    actions: {
      getSkuId: function(element) {
        var prodId = element.attr('data-productid'),
            skuId = element.attr('data-skubaseid'),
            dataKey = element.attr('page-data-key');

        if (typeof skuId === 'undefined') {
        // sku id not supplied, so let's grab one from the page_data variable
          var product = page_data[dataKey].products.filter(function(p) {
            return p.PRODUCT_ID === prodId;
          })[0];
          if (typeof product !== 'undefined') {
            skuId = product.skus[0].SKU_BASE_ID;
          }
        } else {
          skuId = skuId.indexOf('SKU') == -1 ? skuId : skuId.replace('SKU', '');
        }

        return skuId;
      },

      quickview: function(event, element) {
      // Only trigger an overlay if site.quickshop.launch supports it
        if ((typeof site !== 'undefined') &&
          (typeof site.quickshop !== 'undefined') &&
          (typeof site.quickshop.render !== 'undefined')) {
          event.preventDefault();

          // The following code that retreives product data needs to be revisited after Jeremy's code is deployed
          console.log('JGA - revisit Drupal.behaviors.ctaButton.actions.quickview to get product data');
          var $qsPanel = $();
          var prodId = element.attr('data-productid'),
              dataKey = element.attr('page-data-key');
          var product = page_data[dataKey].products.filter(function(p) {
            return p.PRODUCT_ID === prodId;
          })[0];

          var $productElement = element.closest('.js-product');
          if ($productElement.length && product) {
            product.priceRange = $productElement.data('formattedPrice') ||  product.priceRange;
            if (!product.skus) {
              product.skus = page_data["product-input-type"].skus.filter(function(p) {
                if ($productElement.data('formattedPrice') && product['GIFTSET_COMPONENTS'] && p.PRODUCT_ID === prodId) {
                  p.formattedPrice = $productElement.data('formattedPrice');
                }
                return p.PRODUCT_ID === prodId;
              });
            } else {
              product.skus = product.skus.filter(function(p) {
                if ($productElement.data('formattedPrice') && product['GIFTSET_COMPONENTS'] && p.PRODUCT_ID === prodId) {
                  p.formattedPrice = $productElement.data('formattedPrice');
                }
                return p.PRODUCT_ID === prodId;
              });
            }
          }
          // Get the jq object with the rendered quickshop contents
          var $qs = site.quickshop.render({
            productData: product
          });

          // Miscellaneous css tweaks to the quickshop object
          $qs
            .css('display', 'block')
            .find('.close')
            .hide();

          if (typeof $qs === 'object') {
            generic.overlay.launch({
              content: $qs,
              includeBackground: true,
              hideOnClickOutside: true,
              includeCloseLink: true,
              cssStyle: { }
            });
            $qsPanel = $qs.filter('.js-quickshop');
            var sellsWithData = $qsPanel.find('[data-sells-with]');
            if (sellsWithData.length) {
              $(document).trigger('sells_with_init', $qsPanel);
            }
          }

          return false;
        }
      },

      add_to_bag: function(event, element) {
        if ((typeof site !== 'undefined') &&
          (typeof site.addToCart !== 'undefined')) {
          if (!element.is('[data-productid]')) {
            return;
          }

          event.preventDefault();

          var self = this, skuId = self.getSkuId(element);
          if (!element.hasClass('add-both-to-bag')) {
            site.addToCart({
              skuBaseId: skuId
            });
          }

          return false;
        }
      },

      add_to_favorites: function(event, element) {
        if ((typeof site !== 'undefined') &&
          (typeof site.addToFavorites !== 'undefined')) {
          if (!element.is('[data-productid]')) {
            return;
          }

          event.preventDefault();

          var self = this, skuId = self.getSkuId(element);
          site.addToFavorites({
            skuBaseId: skuId
          });

          return false;
        }
      }
    },

    initCTAButton: function(element) {
      var self = this,
          button = element,
          bgColor = button.css('background-color'),
          bgColorInline = /background\-color\:/.test(button.attr('style')),
          bgHoverColor = button.attr('bg-hover'),
          txtColor = button.css('color'),
          txtColorInline = /(?:\-)color\:/.test(button.attr('style')),
          txtHoverColor = button.attr('text-hover'),
          classes = button.attr('class').split(/\s+/),
          action = self.getActionClass(classes);

      // Attach optional event handlers based on the action class
      if (action != false) {
        action = action.replace('action-', '').replace(/\-/g, '_');
        if (!(action in self.actionCbs)) {
          self.actionCbs[action] = $.isFunction(self.actions[action]);
        }

        if (self.actionCbs[action] == true) {
          button.on('click', function(e) {
            self.actions[action](e, $(this));
          });
        }
      }

      // Set up the hover effects for button
      button.hover(
        function() {
          $(this).addClass('hover');

          var css = {};

          if (self.isEmpty(bgHoverColor) == false) {
            css['background-color'] = bgHoverColor;
          }

          if (self.isEmpty(txtHoverColor) == false) {
            css['color'] = txtHoverColor;
          }

          if (self.hasProps(css)) {
            $(this).css(css);
          }
        },
        function() {
          $(this).removeClass('hover');

          var css = {};

          if (bgColorInline) {
            css['background-color'] = bgColor;
          }

          if (txtColorInline) {
            css['color'] = txtColor;
          }

          if (self.hasProps(css)) {
            $(this).css(css);
          }
        }
      );
    },

    init: function(context) {
      context = typeof context !== 'undefined' ? context : document;

      var self = this,
          $buttons = $('.button.cta', context).not('.add-both-to-bag').not('.button_both').once('cta-btn');

      $buttons.each(function() {
        self.initCTAButton($(this));
      });
    },

    // Attaches hover on/off events to all CTA buttons
    attach: function(context, settings) {
      this.init();
    }
  };

  /**
 * Social Share buttons
 */
  Drupal.behaviors.socialShare = {
    attach: function(context, settings) {
      var id = 12345,
          shareLink = encodeURIComponent(window.location.origin + window.location.pathname + '#' + id),
          placeholders = {'%SHARE_LINK%': shareLink},
          buttons = $('.social-share').find('.share-button');

      buttons.each(function() {
        var $me = $(this),
            href = $(this).attr('href');

        href = href.replace(/%\w+%/g, function(all) {
          return placeholders[all] || all;
        });

        $me.attr({
          href: href,
          target: '_blank'
        });
      });
    }
  };

  /**
 * http://www.ideawu.com/prj/jquery-lazy-bind/
 */
  $.fn.lazybind = function(event, fn, timeout, abort) {
    var timer = null;
    $(this).on(event, function(e) {
      var ev = e;
      timer = setTimeout(function() {
        fn(ev);
      }, timeout);
    });
    if (abort == undefined) {
      return;
    }
    $(this).on(abort, function() {
      if (timer != null) {
        clearTimeout(timer);
      }
    });
  };

  /**
 * Add .indexOf() capability to browsers that lack it (mostly <IE9)
 */
  if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function(elt /*, from*/) {
      var len = this.length >>> 0;

      var from = Number(arguments[1]) || 0;
      from = from < 0
        ? Math.ceil(from)
        : Math.floor(from);
      if (from < 0)
        from += len;

      for (; from < len; from++) {
        if (from in this &&
          this[from] === elt)
          return from;
      }
      return -1;
    };
  }

  /**
 * Add Array.filter() capability to browsers that lack it (mostly <IE9)
 * Lifted from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter#Compatibility
 */
  if (!Array.prototype.filter) {
    Array.prototype.filter = function(fun /*, thisArg */) {
      'use strict';

      if (this === void 0 || this === null)
        throw new TypeError();

      var t = Object(this);
      var len = t.length >>> 0;
      if (typeof fun != 'function')
        throw new TypeError();

      var res = [];
      var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
      for (var i = 0; i < len; i++) {
        if (i in t) {
          var val = t[i];

          // NOTE: Technically this should Object.defineProperty at
          //       the next index, as push can be affected by
          //       properties on Object.prototype and Array.prototype.
          //       But that method's new, and collisions should be
          //       rare, so use the more-compatible alternative.
          if (fun.call(thisArg, val, i, t))
            res.push(val);
        }
      }

      return res;
    };
  }

  /**
 * Add String.trim() capability to browsers that lack it (mostly <IE9)
 * Lifted from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim#Compatibility
 */
  if (!String.prototype.trim) {
    String.prototype.trim = function() {
      return this.replace(/^\s+|\s+$/g, '');
    };
  }

  // images with data-circle-color="#HEXVALUE" get a colored circle placed behind them
  Drupal.behaviors.addProductCircles = {
    attach: function(context, settings) {
      var colors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple'];
      var $productImgs = $('img[data-circle-color]');
      $productImgs.each(function(idx, sku) {
        $productImg = $(this);
        var color = $productImg.data('circle-color');
        color = color.toString();
        if (color == 'random') {
	  var selected = Math.floor(Math.random() * 6); //random idx bn 0-5
	  color = colors[selected];
        } else if (!/^#/.test(color)) {
	  // some of these list name before the hex... all we want is the hex
	  color = color.replace(/.* #?/g, '');
	  // prepend the # if needed
	  color = '#' + color;
        }
        var $circle = $('<div/>');
        var width = $productImg.width();
        var height = $productImg.height();
        var marginTop = height / 4;
        $circle.css({
	  'background-color': color,
	  'border-radius': '50%',
	  'height': height,
	  'width': width
        });
        $productImg.wrap($circle);
        $productImg.css({
          'float': 'right',
          'margin-top': marginTop
        });
      });
    }
  };

  // wrapper for selectBox refresh - important for touch devices...
  site.util.refreshSelectBoxes = function($selectBoxes) {
    $selectBoxes.each(function() {
      var control = $(this).data('selectBox-control');
      if (control && control.length) {
        $(this).selectBox('refresh');
      }
    });
  };
  // Implementation of Lazyload. Per template basis.
  // To implement lazyload edit the desired HTML template and add the "lazy" CSS class to the <img> element
  // (NOTE: do not remove the actual <img> CSS classes), then add a "data-original" attr to the <img> element
  // and then remove the "src" attr from the <img> element. E.g.: <img class="lazy" data-original="{{image}}" alt="" />
  // Then edit the template JS file and, inside the "attach" method, add this call: site.util.lazyLoaded(thisFormatter);
  // Note: I'm using above the "thisFormatter" selector, but this can be anything used as the formatter in the main template loop.
  // Note 2: Be sure you attached the lazyload lybrary to the template you're planning to call it from under the js file, e.g.:
  // 'js' => $template_path . '/the_template_v1/the_template_v1.js',
  // 'libraries' => array(
  //    array(
  //      'name' => 'lazyload',
  //      'module' => 'cl_base',
  //    ),
  //  ),
  site.util.lazyLoaded = function(selector, event) {
    event = event || 'scroll';
    selector.find('img').each(function(idx) {
      if ($(this).hasClass('lazy')) {
        $(this).removeAttr('src').hide();
      }
    });
    var base64_img_str = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    selector.find('img.lazy').show().lazyload({
      effect : 'fadeIn',
      event: event,
      failure_limit : 100,
      threshold : 200,
      placeholder : base64_img_str
    });
    selector.find('div.lazy').lazyload({
      failure_limit: 100,
      threshold: 200,
      placeholder: base64_img_str
    });
  };

  /**
 * Wire up behavior on page-load according to js- classnames.
 */
  Drupal.behaviors.prodcatUiInit = {
    attach: function(context) {
      var $product = $('.js-product', context);
      $product.trigger('product.init');
    }
  };
})(jQuery);
